import React, { Component } from 'react';
import { Collapse } from 'components';
import styled from '@emotion/styled';
import { Colors, H1, device } from 'utils';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
const AllgemeinFAQsWrapper = styled.div`
  padding: 20px;

  .faq__collapse {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 0.5px solid ${Colors.secondaryColor};
    h2 {
      font-size: 22px;
      font-weight: 600;
      @media ${device.allMobile} {
        font-size: 18px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 15px;
      color: ${Colors.steel};
      @media ${device.allMobile} {
        font-size: 16px;
      }
      a {
        display: inline-block;
      }
    }
    a {
      font-size: 20px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 15px;
      color: ${Colors.steel};

      display: block;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export default class AllgemeinFAQs extends Component {
  render() {
    const { currentOpen, setCurrentOpen } = this.props;
    return (
      <AllgemeinFAQsWrapper>
        <H1>Allgemein</H1>
        <div>
          <Collapse
            title={<h2>1. Für wen ist commonsplace?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'allgemein1' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'allgemein1'}
          >
            <p>
              Im Allgemeinen eignet sich commonsplace gut für Ideen, die
              gesellschaftliche Probleme lösen, Situationen verbessern oder
              generell einen gesellschaftlichen Mehrwert bringen. commonsplace
              heißt jedoch jede Idee willkommen! Ob du ein Start Up starten, ein
              Obdachlosenprojekt oder ein Communitydinner organisieren willst -
              auf commonsplace findest du deine Community, die dich bei deinem
              Projekt unterstützt. Außerdem kannst du dein Projekt innerhalb der
              deutsch-muslimischen Community bekannt machen. Am Ende musst du
              die Community überzeugen, deine Idee zu unterstützen. Bitte
              informiere dich unter <Link to="/guidelines">Richtlinien</Link>,
              welche Projekte nicht zu commonsplace passen.
            </p>
          </Collapse>
          <Collapse
            title={
              <h2>
                2. Wie garantiert commonsplace, dass Zahlungen sicher sind?
              </h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'allgemein2' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'allgemein2'}
          >
            <>
              <p>
                commonsplace verfügt über sichere Technologien, um Zahlungen zu
                verschlüsseln. Die Zahlungen deiner Unterstützer werden sicher
                abgewickelt und deine Unterstützungen geschützt aufbewahrt, bis
                du sie auszahlen lässt.
              </p>
              <p>
                Stripe hält das Geld bis zur Auszahlungsreife auf einem Client
                Money-Konto des Projektinitiators nach Maßgabe der durch den
                Projektinititiator mit Stripe abgeschlossenen Zusatzvereinbarung
                unter{' '}
                <a href="https://stripe.com/de/connect-account/legal#translation">
                  https://stripe.com/de/connect-account/legal#translation
                </a>
                . Mit der Realisierung des Gesamtziels sendet der
                Projektinitiator die Zahlungsanweisungen für die über
                commonsplace als Plattform gesammelten Unterstützungen an
                Stripe.
              </p>
            </>
          </Collapse>
          <Collapse
            title={<h2>3. Was ist Crowdfunding?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'allgemein3' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'allgemein3'}
          >
            <>
              <p>
                Crowfunding: “Private Projekte, innovative Produkte, Immobilien,
                Startups, etablierte Unternehmen und vieles mehr lassen sich
                durch das Crowdfunding finanzieren. Das Besondere dabei ist,
                dass eine Vielzahl von Menschen ein Projekt finanziell
                unterstützt und so möglich macht. Dabei wenden sich die
                Projektersteller direkt an die Öffentlichkeit, um möglichst
                viele Interessenten für eine gemeinschaftliche Finanzierung zu
                gewinnen. Ob ein Projekt realisiert wird, wird also nicht durch
                eine traditionelle Instanz – wie z.B. eine Bank oder
                Förderinstitution – sondern direkt durch die Community
                entschieden. Ideen können schnell und flexibel umgesetzt werden,
                während Anleger genau das finanzieren, woran sie glauben.
                Projektersteller und die Community können von der direkten
                Finanzierung profitieren.”
                (https://www.crowdfunding.de/was-ist-crowdfunding/)
              </p>
              <p>
                Mehr auf{' '}
                <Link to="/how-it-works">wie funktioniert commonsplace.de</Link>
              </p>
            </>
          </Collapse>
          <Collapse
            title={<h2>4. Wofür steht der Name commonsplace?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'allgemein4' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'allgemein4'}
          >
            <>
              <p>
                Mit Commons, aus dem lateinischen “cum” (etwas verbinden/mit)
                und “munus” (Pflicht), bezeichnet man Ressourcen und Produkte
                unterschiedlicher Art, die aus selbstorganisierten Prozessen
                hervorgehen. Es sind schöpferische, und lebendige Prozesse, in
                denen Ideen selbstbestimmt und unabhängig realisiert und
                Bedürfnisse befriedigt werden. Commons entstehen, wenn Menschen
                sich als Gleichrangige bewusst selbst organisieren, kollektiv
                entwickeln, sowie bedürfnisorientiert erzeugen und gestalten.
              </p>
              <Link to="/about-us">Mehr auf Über uns</Link>
            </>
          </Collapse>
          <Collapse
            title={<h2>5. Wer ist euer Zahlungsdienstpartner?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'allgemein5' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'allgemein5'}
          >
            <p>
              Für die Abwicklung der Zahlungsmethoden arbeitet commonsplace mit
              dem Paymentdienstleister Stripe Technology Europe Ltd., 25-28
              North Wall Quay, Dublin 1, Ireland zusammen. Mehr auf:
              <a
                href="https://stripe.com/en-de"
                target="_blank"
                rel="noreferrer"
              >
                https://stripe.com/en-de
              </a>
            </p>
          </Collapse>
          <Collapse
            title={<h2>6. Was kostet die Nutzung von commonsplace?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'allgemein6' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'allgemein6'}
          >
            <p>
              commonsplace ist für Unterstützer kostenlos. Für
              Projektinitiatoren fallen Verwaltungs- und
              Zahlungsabwicklungsgebühren an. Mehr auf{' '}
              <Link to="/fees">Gebühren-Seite</Link>
            </p>
          </Collapse>
          <Collapse
            title={
              <h2>
                7. Welche Vorteile habe ich, wenn ich mich auf commonsplace
                registriere?
              </h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'allgemein7' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'allgemein7'}
          >
            <p>
              Du kannst Projekte favorisieren, Spenden verwalten, deine
              Zahlungsdaten speichern, Projekte erstellen und verwalten und
              Newsletter erhalten: Du bist Teil der Community
            </p>
          </Collapse>
          <Collapse
            title={<h2>8. Wie kann ich commonsplace kontaktieren?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'allgemein8' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'allgemein8'}
          >
            <p>Sende uns dein Anliegen an salam@commonsplace.de</p>
          </Collapse>
          <Collapse
            title={
              <h2>9. Kann ich meinen Account bei commonsplace löschen?</h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'allgemein9' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'allgemein9'}
          >
            <p>
              Dich als Teil der Community zu verlieren, ist sehr traurig. Gerne
              würden wir uns mit dir zusammensetzen und dein Problem anhören.
              Bitte kontaktiere uns in jedem Fall unter salam@commonsplace.de.
            </p>
          </Collapse>
          <Collapse
            title={<h2>10. Wie kann ich meine persönlichen Daten ändern?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'allgemein10' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'allgemein10'}
          >
            <p>Mein Profil → Einstellungen → Persönliche Daten</p>
          </Collapse>
          <Collapse
            title={<h2>11. Sind meine Daten auf commonsplace sicher?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'allgemein11' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'allgemein11'}
          >
            <p>
              Ja. Deine Daten sind unser wichtigstes Anliegen. Wir gehen mit
              deinen Daten diskret um. Schaue dir hierfür{' '}
              <Link to="datenschutz">unsere Datenschutzerklärung an.</Link>
            </p>
          </Collapse>
          <Collapse
            title={<h2>12. Was sind Goodies? </h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'allgemein12' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'allgemein12'}
          >
            <p>
              Ein Projekt kann in der Finanzierungsphase durch eine freie
              Unterstützung oder durch die Auswahl eines Goodies unterstützt
              werden. Goodies sind Gegenleistungen, die man vom Projektinitiator
              für seine Unterstützung erhält.
            </p>
          </Collapse>
          {/* <Collapse
            title={<h2>14. Wie sind die Etappenziele aufgebaut? </h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'allgemein14' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'allgemein14'}
          >
            <p>
              Sobald das erste Etappenziel erreicht wurde, wird die nächste
              Etappe freigeschaltet. Sobald die Summe dieser Etappe
              eingesammelt, wird wieder die nächste Etappe freigeschaltet.
              Solange bis das Ende der Finanzierungsphase erreicht ist. Dann
              wird ausgezahlt.
            </p>
            <p>
              Die Etappenziele basieren auf der folgenden Zahlenfolge und sind
              für jedes Projekt gleich:
            </p>
            <p>
              100 € - 1. Etappe <br />
              200 € - 2. Etappe <br />
              300 € - 3. Etappe <br />
              500 € - 4. Etappe <br />
              800 € - 5. Etappe <br />
              1.300 € - 6. Etappe <br />
              2.100 € - 7. Etappe <br />
              3.400 € - 8. Etappe <br />
              5.500 € - 9. Etappe <br />
              8.900 € - 10. Etappe <br />
              14.400 € - 11. Etappe <br />
              23.300 € - 12. Etappe <br />
              37.700 € - 13. Etappe <br />
              61.000 € - 14. Etappe <br />
              98.700 € - 15. Etappe <br />
              usw.
            </p>
            <p>
              Beispiel: Wenn dein erstes Etappenziel bei 2.500€ liegt, dann ist
              die nächste Etappe bei 3.400 € erreicht.
            </p>
          </Collapse> */}
        </div>
      </AllgemeinFAQsWrapper>
    );
  }
}

AllgemeinFAQs.propTypes = {
  currentOpen: PropTypes.string,
  setCurrentOpen: PropTypes.func.isRequired,
};
