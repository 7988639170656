import React, { Component } from 'react';
import { Collapse } from 'components';
import styled from '@emotion/styled';
import { Colors, H1, device } from 'utils';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
const UnterstützerFAQsWrapper = styled.div`
  padding: 20px;
  margin: 100px auto;
  .faq__collapse {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 0.5px solid ${Colors.secondaryColor};
    h2 {
      font-size: 22px;
      font-weight: 600;
      @media ${device.allMobile} {
        font-size: 18px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 15px;
      color: ${Colors.steel};
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
  }
`;

export default class UnterstützerFAQs extends Component {
  render() {
    const { currentOpen, setCurrentOpen } = this.props;
    return (
      <UnterstützerFAQsWrapper>
        <H1>Unterstützer</H1>
        <div>
          <Collapse
            title={
              <h2>
                1. Wann gilt ein Projekt als erfolgreich und was passiert mit den Unterstützungen, wenn ein Projekt nicht erfolgreich ist?
              </h2>

            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'unterstuzer1' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'unterstuzer1'}
          >
            <>
              <p>
                Ein Projekt gilt bei commonsplace als erfolgreich, wenn das erste Etappenziel erreicht wurde. Gelder bei nicht-erfolgreichen Projekten werden an die Unterstützer zurück gesendet.
              </p>
              <p>
                Bei sozialen Projekten kann das Geld auch ausgezahlt werden,
                wenn das Fundingziel nicht erreicht wurde.
              </p>
              <p>
                Bei StartUps und Unternehmen können die Unterstützungen nur
                ausgezahlt werden, wenn das Fundingziel erreicht wird. Das Geld
                geht dann an den Unterstützer zurück
              </p>
            </>
          </Collapse>
          <Collapse
            title={
              <h2>2. Kann ich meine Unterstützung von der Steuer absetzen?</h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'unterstuzer2' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'unterstuzer2'}
          >
            <p>
              Wenn die von dir unterstützte Organisation gemeinnützig ist,
              kannst du eine Spendenbescheinigung bei der Organisation anfragen.
            </p>
          </Collapse>
          <Collapse
            title={<h2>3. Wie erhalte ich meine Spendenbescheinigung?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'unterstuzer3' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'unterstuzer3'}
          >
            <p>
              commonsplace kann keine Spendenbescheinigungen ausstellen. Auf den jeweiligen Projektseiten wird jedoch angezeigt, ob der Projektinitiator Spendenbescheinigungen ausstellen kann. Mit der Zahlungsbestätigungsmail erhältst du auch die E-Mail-Adresse des Projektinitiators, damit du die Spendenbescheinigung anfordern kannst. Wenn du keine Rückmeldung vom Projektinitiator erhalten hast, kannst du dich gerne an unser Support-Team unter salam@commonsplace.de wenden. Wir helfen dir dabei, den Kontakt herzustellen.
            </p>
          </Collapse>
          <Collapse
            title={
              <h2>
                4. Wie kann ich mich über Neuigkeiten eines Projektes
                informieren?
              </h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'unterstuzer5' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'unterstuzer5'}
          >
            <p>
              Auf der Projektseite kannst du im Bereich „Updates“ über
              regelmäßige Neuigkeiten erfahren
            </p>
          </Collapse>
          <Collapse
            title={<h2>5. Kann ich aus dem Ausland spenden?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'unterstuzer6' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'unterstuzer6'}
          >
            <p>Ja, auch aus dem Ausland kann man die Community unterstützen.</p>
          </Collapse>
          <Collapse
            title={<h2>6. Wie kann ich ein Projekt anonym unterstützen?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'unterstuzer7' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'unterstuzer7'}
          >
            <p>
              Bevor du ein Projekt unterstützt und deinen Geldbetrag absendest,
              wirst du gefragt, ob du anonym spenden möchtest.
            </p>
          </Collapse>
          <Collapse
            title={<h2>7. Welche Bezahlmethoden gibt es auf commonsplace?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'unterstuzer8' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'unterstuzer8'}
          >
            <p>
              Kreditkarte, Paypal, Klarna, SEPA-Lastschrift, Google Pay, Apple Pay, Link Pay and Ideal.
            </p>
          </Collapse>
          <Collapse
            title={
              <h2>
                8. Wo wird meine Spende bis zum Erreichen des Gesamtziels
                aufbewahrt?
              </h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'unterstuzer9' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'unterstuzer9'}
          >
            <p>
              Stripe hält das Geld bis zur Auszahlungsreife auf einem Client
              Money-Konto des Projektinitiators nach Maßgabe der durch den
              Projektinititiator mit Stripe abgeschlossenen Zusatzvereinbarung
              unter{' '}
              <a href="https://stripe.com/de/connect-account/legal#translation">
                https://stripe.com/de/connect-account/legal#translation
              </a>
              . Mit der Realisierung des Gesamtziels sendet der
              Projektinitiator die Zahlungsanweisungen für die über commonsplace
              als Plattform gesammelten Unterstützungen an Stripe.
            </p>
          </Collapse>
          <Collapse
            title={
              <h2>
                10. Wie kann ich sicher gehen, dass meine Spende wirklich
                ankommt?
              </h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'unterstuzer10' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'unterstuzer10'}
          >
            <p>
              Siehe:{' '}
              <Link to="/transparency-and-trust">
                {' '}
                Vertrauen und Sicherheit{' '}
              </Link>
              .
            </p>
          </Collapse>
          <Collapse
            title={
              <h2>11. Wie kann ich meine Spende nachträglich anonymisieren?</h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'unterstuzer11' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'unterstuzer11'}
          >
            <p>Mein Profil → Einstellungen → Unterstützungen → anonymisieren</p>
          </Collapse>
          <Collapse
            title={
              <h2>12. Was kann ich tun, wenn ich mein Goody nicht erhalte?</h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'unterstuzer12' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'unterstuzer12'}
          >
            <p>
              In der Bestätigungsmail, in deinem Profil unter "Meine Goodies"
              und auf der Projektseite kannst du nachschauen, ob dein Goody
              schon versandt wurde. Sollte es zu Verzögerungen oder anderen
              Problemen kommen, konsultiere das commonsplace Team.
            </p>
          </Collapse>
          <Collapse
            title={<h2>13. Wann bekomme ich mein Goody?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'unterstuzer13' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'unterstuzer13'}
          >
            <p>
              Goodies erhältst du entweder einige Tage nach Erreichen des ersten
              Etappenziels oder sobald das Projekt erfolgreich finanziert wurde
              - das ist von Goody zu Goody unterschiedlich. Wenn das erste
              Etappenziel nicht erreicht wurde, erhältst du dein Geld zurück.
              <p>
                Informationen auf die voraussichtliche Lieferzeit findest du in
                deiner Bestätigungsmail oder in der Beschreibung des Goodies auf
                der Projektseite.
              </p>{' '}
              <p>
                Sollte es dazu kommen, dass der Projektinitiator dein Goody
                nicht versendet und dir nicht antwortet, kannst du dein Geld vom
                Projektinitiator zurückfordern und im schlimmsten Fall einen
                Anwalt einschalten. Mit deiner Unterstützung schließt du einen
                Vertrag über die Lieferung deines Goodies mit mit dem
                Projektinitiator und hast somit rechtlich Anspruch darauf.
                Weitere Informationen dazu findest du im folgenden Artikel von
                Rechtsanwalt Sven Hörnich:{' '}
                <b>
                  <a href="https://sven-hoernich.de/alles-nur-spass-ueber-die-verbindlichkeit-sog-crowdfunding-vertraege/">
                    Alles nur Spass? Über die Verbindlichkeit sog.
                    „Crowdfunding-Verträge“
                  </a>{' '}
                </b>
              </p>
            </p>
          </Collapse>

          <Collapse
            title={<h2>14. Wiederkehrende Unterstützungen</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'unterstuzer14' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'unterstuzer14'}
          >
            <p>
              Wiederkehrende Unterstützungen sind eine Funktion auf commonsplace, die es dir ermöglicht, Projekte regelmäßig zu unterstützen. Du kannst dabei wählen, ob du täglich, wöchentlich oder monatlich beitragen möchtest. Diese Einstellung wird direkt im Check-Out-Prozess vorgenommen, sodass die Unterstützung mit nur einem Klick aktiviert werden kann. Um diese Funktion zu nutzen, benötigst du ein commonsplace Account, das du einfach und schnell während des Check-Out-Prozesses erstellen kannst. Die wiederkehrende Unterstützung lässt sich jederzeit in deinem commonsplace-Profil verwalten oder über den in der Bestätigungs-E-Mail gesendeten Link stornieren.
            </p>
          </Collapse>
        </div>
      </UnterstützerFAQsWrapper>
    );
  }
}

UnterstützerFAQs.propTypes = {
  currentOpen: PropTypes.string,
  setCurrentOpen: PropTypes.func.isRequired,
};
